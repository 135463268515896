<template>
    <div>
      <van-nav-bar left-arrow left-text="返回" title="钱包" @click-left="goback"/>
        <div class="blance-wrap">
            <span>当前余额</span>
            <div class="blance-row">
                <span class="blance">{{ balance }}日元</span>
                <button class="recharge-btn" @click="rechargeClick">充值</button>
             </div>
        </div>

        <div class="detail-wrap">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <div class="details-item" v-for="(item, index) in list" :key="index">
                <div class="details-attr">
                  <span class="item-type">{{ item.memo }}</span>
                  <span class="item-no">{{ item.ordersn }}</span>
                </div>
                <div class="details-attr" style="margin-top: 5px">
                  <span class="item-time">{{ formatDate(item.createtime) }}</span>
                  <span class="item-money" :class="{ income: item.money > 0 }">{{ item.money }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
        
        <!-- <van-tabs v-model="active" @click="onClick">
            <van-tab title="资金明细">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                      <van-cell v-for="(item,index) in list" :key="index"  :title="item.money+'日元'" :value="item.ordersn" :label="formatDate(item.createtime)" @click="toOrderDetials(item.orderid)" />
                    </van-pull-refresh>
                </van-list>
            </van-tab>
            <van-tab title="退款明细">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-cell v-for="(item,index) in list" :key="index" :title="item.money+'元'" :value="item.memo" :label="formatDate(item.createtime)" />
                        </van-list>
                    </van-pull-refresh>
               </van-list>
            </van-tab> 
        </van-tabs> -->
       
        <van-dialog v-model="show" title="充值" show-cancel-button @confirm="rechargeMethod">
            <van-form>
                <van-field v-model="form.money"  type="number" label="金额(日元)" />
                <van-radio-group v-model="form.type" direction="horizontal">
                    <van-radio name="alipay">支付宝</van-radio>
                    <van-radio name="offline">线下支付</van-radio>
                </van-radio-group>
            </van-form>
            
        </van-dialog>
    </div>
</template>
<script>
import {getBalanceDetails,recharge,balance,getExpressDetails} from '../tool/api'
import { Toast } from 'vant';
import ap from '../tool/ap'
export default {
    data(){
        return{
            active:1,
            show:false,
            list: [],
            type:0,
            loading: false,
            finished: false,
            refreshing: false,
            balance:0,
            form:{
                money:0,
                paytype:'alipay',
                method:'wap'

            }
        }
    },
    created(){
        balance().then(a=>{
            this.balance=a.data;
        })
    },
    methods:{
      goback() {
      history.back()
    },
        onClick(name, title){
             
            this.type=0; 
            this.onLoad();
        },
        formatDate(data){
            return this.$formatDate(data);
        },
        rechargeClick(){
            // this.show=true;
             this.$router.push({ path:"/walletPay"})
        },
        toOrderDetials(val){
            getExpressDetails(val).then(a=>{ 
                this.$router.push({path:'/orderDetails', query: {mid:a.data.express_m_id}})
            });
        },
        onLoad() {
                if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
                } 
                getBalanceDetails(this.type).then(a=>{
                    if(a.code===1){
                        this.list=a.data;
                        this.finished = true;
                    }
                        
                })
               this.loading = false;
                
        },
        rechargeMethod(){ 
                if(this.form.money==0){
                    Toast.fail("充值金额不能为小于等于0");
                    return ;
                }
                recharge(this.form).then(a=>{  
                    if(a.code===1){
                        //支付宝
                        if(this.form.type==='alipay'){   
                            const div = document.createElement('div');
                            div.innerHTML = a.data;
                            document.body.appendChild(div);
                            document.forms[0].acceptCharset='utf-8';
                            var queryParam = '';
                            Array.prototype.slice
                                .call(
                                document
                                .querySelectorAll("input[type=hidden]"))
                                .forEach(
                                function(ele) {
                                    queryParam += ele.name
                                        + "="
                                        + encodeURIComponent(ele.value)
                                        + '&';
                                });
                                let url = document.forms[0].action+ '&' + queryParam
                                console.log(url);
                                ap.pay(url)   
                        }else{
                            Toast.success("请于服务员核对");
                        }        
                    }else{
                        Toast.fail(`调用接口异常:${a.msg}`);
                    }     
            })
        },
        onRefresh() {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
        },
    }
    
}
</script>

<style>
.blance-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px; 
  color: #333;
  font-size: 12px;
  padding: 0 20px;
  border-radius: 10px;
  background-color: #fff;
}

.blance-wrap .blance {
   color: #e01616;
   font-size: 18px;
}

.blance-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.recharge-btn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border: 0;
  background-color: #e01616;
}

.detail-wrap {
  margin-top: 10px;
}

.details-item {
  padding: 10px;
  border-bottom: 1px solid #f2f3f5;
  background-color: #fff;
}

.details-attr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-type {
  color: #333;
  font-size: 14px;
}

.item-no {
  color: #333;
  font-size: 12px;
}

.item-time {
  color: #999;
  font-size: 12px;
}

.item-money {
  color: #333;
  font-size: 16px;
}

.income {
  color: #e01616;
}
</style>