<template>
<div>
    <van-nav-bar left-arrow left-text="返回" title="消息详情" @click-left="goback"/>
    <div v-html="data" class="divBox"></div>
</div>
   
</template>
<style scoped>
/deep/ section{
    margin:0px;
    padding:0px;
    display:-webkit-flex;
}
</style>
<script>

import {consignmentInfo} from '../tool/api'
export default {
    data(){
        return{
            id:-1,
            data:'',
            imgUrls:[]
        }
    },
    created(){  
         this.id=this.$route.query.id;
         this.getData();
    },
    methods:{
        goback() {
            history.back()
        },
        getData(){
            consignmentInfo(this.id).then(a=>{ 
                console.log(a.data.content.indexOf("img"));
                if(a.data.content.indexOf("img")<2){
                    let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
                    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
                    let arr = a.data.content.match(imgReg)  //筛选出所有的img 
                    let src = arr[0].match(srcReg);
                    this.data='<img src="'+"https://kd.sf-nagoya.com/"+src[1]+'" alt="" style="width:100%"/>'

                }else{
                    this.data=a.data.content.replace(/width:558px;/g,"");
                    this.data=this.data.replace(/height:auto !important;/g,"height:20px;")
                }
                
                
            })
        }
    }
}
</script>