<template>
  <div> 
      <van-nav-bar left-arrow left-text="返回" title="箱单列表" @click-left="goback"/>
        <van-field  :label="index+1" input-align="right" :value="item.orderNo" is-link   v-for="(item,index) in digit" :key="index" v-if="cellShow"  @click="toDelivery(index)"/>  
       
        <van-dialog v-model="show" title="箱单数量" @confirm="confirmMethod"> 
           <van-field v-model="orderNum" type="digit" label="箱单数量" />
        </van-dialog>
  </div>
</template>

<script>
export default {
    name:'expressList',
    data(){
        return{
            cellShow:false,
            show:false,
            digit:[],
            orderNum:0
        }
    },
    created(){
        this.show=true;
    },    
    methods:{
        goback() {
            history.back()
        },
        toDelivery(val){
            this.$router.push({path:'/expressDelivery',query: { index:val }});
        },
        confirmMethod(){
            for(let i=0;i<this.orderNum;i++){
                this.digit.push({num:i+1,orderNo:''});
                // this.$store.list.push({num:i})
            }
            this.cellShow=true;
            this.show=false;
            
            console.log(this.$store);
        }    
    }
}
</script>

<style>

</style>