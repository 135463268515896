<template>
    <div>
         <van-nav-bar left-arrow left-text="返回" title="免责说明" @click-left="goback"/>
		<div v-for="(item,index) in list" :key="index">
			<div class="nav-hd">{{item.name}}</div>
			<div class="nav-item" v-for="(items,indexs) in item.child" :key="indexs" @click="goDetails(items.id)">
          <span>{{ items.title }}</span>
				<van-icon name="arrow" color="#999" size="14"/>
			</div>
		</div>
	</div>
</template>
<script>
import {getReliefList} from '../tool/api'


export default {
    data(){
        return{
            list: []
        }
    },
    created(){
        getReliefList().then(a=>{
            this.list = a.data
        })
    },
    methods: {
        goback() {
            history.back()
        },
        goDetails(id) {
            // this.$router.push({path:'/statement'})
            this.$router.push({ name: 'Statement', params: { id: id }})
        }
    }
}
</script>

<style scoped>
.nav-hd {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    color:#000;
    font-size: 13px;
    font-weight: bold;
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 0 15px;
    color:#333;
    font-size: 13px;
    border-bottom: 1px solid #f2f3f5;
    background-color: #fff;
}
</style>

<style>
.van-nav-bar .van-icon {
    color: #999;
}
.van-nav-bar .van-nav-bar__text {
  color: #999;
}
</style>