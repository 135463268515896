<template>
<van-cell-group>
    <div class="cardDiv">
        <!-- <div class="title">身份证</div> -->
        <div class="img">
            <ul style="display:inline"> 
                <li>
                    <div>
                        <!--  @oversize="onOversize" -->
                        <van-uploader v-model="positive" v-show="!flag" accept="image/png, image/jpeg,image/jpg"  :after-read="positiveAfterRead" :preview-size="100" :max-count="1" />
						
						<!-- bse64回显 -->
						<div style="width: 100px;height: 100px;position: relative;" v-show="flag">
							<img v-if="positive.length && positive[0].url" :src="positive[0].url" alt="" style="width: 100%;height: 100%;object-fit: cover;">
							<div class="van-uploader__preview-delete" @click="detBase('front')">
								<i class="van-icon van-icon-cross van-uploader__preview-delete-icon"></i>
							</div>
						</div>
                    </div>
                    <div>
                        身份证-个人面 
                    </div> 
                </li>
                <li>
                     <div>
                         <!--  @oversize="onOversize" -->
                        <van-uploader  v-model="verso"   :after-read="versoAfterRead" :preview-size="100" @delete="detBase('back')" :max-count="1" />
                    </div>
                    <div>
                        身份证-国徽面
                    </div>
                </li>
            </ul>
        </div>
    </div>
</van-cell-group>
</template>
<script>
import { Toast } from 'vant';
import {idCardCheck,uploadFile, uploadFileNew, getBase64} from '../tool/api'
export default {
    props:{
        idcardimage:String,
        idcardbackimage:String
    },
    data(){
        return{
			flag:false,
            positive:[],
            verso:[],
            form:{
                idCard:'',
                positive:'',
                userName:'',
                verso:'',
                isVerso: false
            }
        } 
    },
    mounted(){
        if(this.idcardimage!=undefined&&this.idcardbackimage!=undefined){
            // console.log(this.$imgUrl+this.idcardimage)
            // 获取base64格式图片
            getBase64(this.idcardimage).then((response) => {
            	this.positive.push({url:response.data.idcardimage});
				this.flag=true;
            })
            this.verso.push({url:this.$imgUrl+this.idcardbackimage});
        }
    },
    methods:{
        onOversize(file) {
            console.log(file);
            Toast('文件大小不能超过1M');   
        },
		// 移除base回显图
		detBase(data) {
			if(data=='front') {
				this.flag=false;
				this.positive=[];
				this.form.positive='';
			}
			if(data=='back') {
				this.verso=[];
				this.form.verso='';
			}
			this.$emit("fallBack",this.form);
		},
        positiveAfterRead(val){  
            let that=this;
            let formData = new FormData()
            val.status = 'uploading';
            val.message = '上传中...';
            formData.append('file', val.file)
            uploadFileNew(formData).then(a=>{
                this.form.isVerso=false
                this.form.positive=a.data.url;
                if(this.positive.length>0&&this.verso.length>0){
                    this.$emit("fallBack",this.form);
                }
                if(a.code===1){
                    idCardCheck(a.data.url,"front") .then(s=>{
                        if(s.code===1){ 
                            if(s.data.words_result.姓名===null||s.data.words_result.姓名==undefined){
                                // Toast("请上传身份证正面!!");    
                                Toast.fail("正面身份证号码读取失败，请重新上传");
                                val.status = 'fial';
                                val.message = '请上传身份证正面';
                                this.$emit("fallBack",this.form);
                            }else{
                                this.positive[0]==val.content; 
                                this.form.userName=s.data.words_result.姓名.words;  
                                this.form.idCard=s.data.words_result.公民身份号码.words;
                                this.form.positive=a.data.url;
                                val.status = 'success';    
                                val.message = '上传成功';
                                if(this.positive.length>0&&this.verso.length>0){
                                    this.$emit("fallBack",this.form);
                                }
                            }
                            
                        }else{         
                            Toast.fail("正面身份证号码读取失败，请重新上传");                   
                            val.status = 'fial';
                            val.message = '上传失败'; 
                            // this.$emit("fallBackError",this.form);
                        }   
                    }).catch(function(){                        
                        val.status = 'failed';
                        val.message = '识别超时,请重新上传或手动填写'; 
                        that.$emit("fallBackError","");
                    })  
                }
            })
            
        },
        versoAfterRead(val){
            let that=this;
            let formData = new FormData()
            val.status = 'uploading';
            val.message = '上传中...';
            formData.append('file', val.file)
            uploadFile(formData).then(a=>{
                this.form.isVerso=true
                this.form.verso=a.data.url;
                if(this.positive.length>0&&this.verso.length>0){
                    this.$emit("fallBack",this.form);
                }
                if(a.code===1){
                    idCardCheck(a.data.url,"back") .then(s=>{
                        if(s.code===1){  
                            if(s.data.words_result.签发日期===null||s.data.words_result.签发日期==undefined){
                                Toast.fail("反面身份证号码读取失败，请重新上传");
                                val.status  = 'failed';
                                val.message = '请上传身份证反面'; 
                            }else{
                                this.verso[0]==val.content;                       
                                val.status = 'success';    
                                val.message = '上传成功';
                                this.form.verso=a.data.url;
                               if(this.positive.length>0&&this.verso.length>0){
                                    this.$emit("fallBack",this.form);
                                }
                            }
                            
                        }else{              
                            Toast.fail("反面身份证号码读取失败，请重新上传");              
                            val.status = 'failed';
                            val.message = '上传失败'; 
                            // this.$emit("fallBackError",this.form);
                        }   
                    }).catch(function(){
                        val.status = 'failed';
                        val.message = '识别超时,请重新上传'; 
                        that.$emit("fallBackError","");
                    }) 
                }
            })
        }
    }
}
</script>
<style scoped>
.cardDiv{
    margin-top: -2px;
    padding: 10px 0px 10px 0px;
    float: left;
    width: 100%;
    background: white; 
    box-sizing: border-box;
}
.cardDiv .title{
    padding: 10% 0px 0px 16px;
    text-align: center;
    vertical-align:middle ;
    font-size: 14px; 
    margin: 0px;
    float: left;
}
.cardDiv .img{
    font-size: 14px;
    
    margin: 0px;
}
.cardDiv .img ul li{
    font-size: 12px;
    float: left;
    text-align: center; 
    margin-left: 16.5%;
}

</style>