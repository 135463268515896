<template>
  <div>
    <div class="serach-wrap">
      <div class="serach-row">
          <van-icon name="search" size="15"/>
          <input class="search-input" v-model="query.search" placeholder="输入收件人姓名/电话" />
      </div>
      <button class="serach-btn" @click="onSearch">搜索</button>
    </div>

    <div class="tabs-wrap">
        <div class="tabs-nav">
            <div class="tabs-item" :class="{'tabs-active':tabcur===1}" @click="search(1)">
                <span>近七天</span>
            </div>
            <div class="tabs-item" :class="{'tabs-active':tabcur===2}" @click="search(2)">
                <span>近一个月</span>
            </div>
        </div>
        <div class="tabs-cus" :class="{'tabs-active':tabcur===3}" @click="search(3)">
            <span>自定义</span>
            <van-icon name="filter-o" color="#ee0a24" size="15"/>
        </div>
    </div>

    <!-- <van-row
      type="flex"
      justify="space-around"
      style="padding-bottom: 10px; background-color:#fff">
      <van-col style="font-size: 12px;" span="5"  v-for="(item, index) in btnList" :key="index" :class="{noActive: item.isActive == false, active: item.isActive == true}" @click="search(item.value, index)">
        {{ item.name }}
      </van-col>
    </van-row> -->

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list style="margin-bottom: 100px" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" >
        <div class="order-item" v-for="(item, index) in list" :key="index">
            <span class="order-hd">订单单号：{{ item.sn }}</span>
            <span class="order-attr">总重量：{{ item.money_weight }}KG</span>
            <span class="order-attr">包裹数量：{{ item.express_count }}</span>
            <span class="order-attr">揽收时间：{{ item.addtime_text }}</span>
            <div class="order-footer">
                <button v-if="item.status === 1" size="mini"  @click="payOrder(item.id)">订单支付</button>
                <button v-else size="mini" @click="orderDetails(item.id)">订单详情</button>
            </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-calendar v-model="show" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
  </div>
</template>

<script>
import { Toast } from "vant";
import { getOrderList } from "../tool/api";
export default {
  data() {
    return {
      loading: false,
      tabcur: 1,
      tabs: [
        { name: "近七天", value: 1, isActive: true },
        { name: "近一个月", value: 2, isActive: false }
      ],
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      btnList: [
        // { name: "今天", value: 0, isActive: true },
        { name: "近七天", value: 1, isActive: true },
        { name: "近一个月", value: 2, isActive: false },
        { name: "自定义", value: 3, isActive: false },
      ],
      btnType: 0,
      option1: [
        { text: "收件人", value: 0 },
        { text: "收件电话", value: 1 },
      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      show: false,
      count: 0,
      btnIndex: 0,
      isLoading: false,
      active: "",
      value: "",
      query: {
        //开始时间
        begintime: "",
        //结束时间
        endtime: "",
        //订单号
        orderNo: "",
        page: 1,
      },
    };
  },
  methods: {
    onSearch() {
      this.query.begintime = null;
      this.query.endtime = null;
      this.search(4, this.btnIndex);
    },
    payOrder(val) {
      this.$router.push({ path: "/pay", query: { mid: val } });
    },
    /**
     * 订单详情
     * **/
    orderDetails(val) {
      this.$router.push({ path: "/orderDetails", query: { mid: val } });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }
        getOrderList(this.query)
          .then((a) => {
            if (a.code === 1) {
              // Toast.success("查询成功");
              this.list = a.data;
            } else {
            }
            this.loading = false;
            this.finished = true;
            this.refreshing = false;
          })
          .catch((a) => {
            this.loading = false;
            this.finished = true;
            this.refreshing = false;
          });
      }, 1000);
    },
    onRefresh() {
      setTimeout(() => {
        Toast.success("刷新成功");
        this.isLoading = false;
        this.count++;
      }, 1000);
    },
    onConfirm(val) {
      this.query.begintime = this.timeFormat(val[0]);
      this.query.endtime = this.timeFormat(val[1]);
      this.show = false;
      getOrderList(this.query).then((a) => {
        this.loading = false;
        Toast.success("查询成功");
        this.list = a.data;
      });
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      return year + "-" + month + "-" + day;
    },
    getContainer() {
      return document.querySelector("#cus");
    },
    search(val) {
      // this.btnIndex = index;
      // for (let i = 0; i < this.btnList.length; i++) {
      //   this.btnList[i].isActive = false;
      // }
      // this.btnList[this.btnIndex].isActive = true;
      this.tabcur = val
      if (this.query.search == "") {
        this.query.search = null;
      }
      this.loading = true;
      var date = new Date();
      let year = date.getFullYear(); //获取完整的年份(4位)
      let month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let day = date.getDate(); //获取当前日(1-31)
      if (val === 0) {
        this.query.begintime = year + "-" + month + "-" + day + " 00:00:00";
        this.query.endtime = year + "-" + month + "-" + day + " 23:59:59";
        getOrderList(this.query).then((a) => {
          this.loading = false;
          Toast.success("查询成功");
          this.list = [];
          this.list = a.data;
        });
      } else if (val === 1) {
        var date = new Date();
        var oneweekdate = new Date(date - 7 * 24 * 3600 * 1000);
        var y = oneweekdate.getFullYear();
        var m = oneweekdate.getMonth() + 1;
        var d = oneweekdate.getDate();
        var formatwdate = y + "-" + m + "-" + d + " 00:00:00";
        this.query.begintime = formatwdate;
        this.query.endtime = year + "-" + month + "-" + day + " 23:59:59";
        getOrderList(this.query).then((a) => {
          Toast.success("查询成功");
          this.loading = false;
          this.list = [];
          this.list = a.data;
        });
      } else if (val === 2) {
        date.setMonth(date.getMonth() - 1);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var formatwdate = y + "-" + m + "-" + d;

        this.query.begintime = formatwdate + " 00:00:00";
        this.query.endtime = year + "-" + month + "-" + day + " 23:59:59";
        getOrderList(this.query).then((a) => {
          Toast.success("查询成功");
          this.loading = false;
          this.list = [];
          this.list = a.data;
        });
      } else if (val === 3) {
        this.show = true;
      } else if (val === 4) {
        getOrderList(this.query).then((a) => {
          this.loading = false;
          Toast.success("查询成功");
          this.list = [];
          this.list = a.data;
        });
      }
    },
  },

  created() {
    var date = new Date();
    let year = date.getFullYear(); //获取完整的年份(4位)
    let month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    let day = date.getDate(); //获取当前日(1-31)
    //  this.query.begintime=year+"-"+month+"-"+day+" 00:00:00";
    //  this.query.endtime=year+"-"+month+"-"+day+" 23:59:59";
    getOrderList(this.query).then((a) => {
      this.list = [];
      this.list = a.data;
    });
  },
};
</script> 

<style scoped>
.serach-wrap {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #fff;
}

.serach-row {
    flex: 1;
    display: flex;
    align-items: center;
    height: 34px;
    padding-left: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #f2f3f5;
}

.search-input {
    flex: 1;
    height: 34px;
    border: 0;
    color: #333;
    font-size: 12px;
    margin-left: 3px;
    background-color: transparent;
}

.serach-btn {
    width: 70px;
    height: 34px;
    color: #fff;
    font-size: 12px;
    border: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #e01616;
}

.tabs-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 10px 12px;
    background-color: #fff;
}

.tabs-nav {
    flex: 1;
    display: flex;
    align-items: center;
}

.tabs-item {
  line-height: 30px;
  color: #646566;
  font-size: 14px;
  margin-right: 30px;
}

.tabs-active {
  color: #323233;
  font-weight: 500;
  border-bottom: 2px solid #e01616;
}

.tabs-cus {
    display: flex;
    align-items: center;
    color: #646566;
    font-size: 14px;
    line-height: 20px;
}

.noActive {
  background-color: #dddddd;
  font-size: 10px;
  text-align: center;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 1rem;
}
.active {
  background-color: #e01616;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 1rem;
}

.order-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    color: #646566;
    font-size: 13px;
    border-radius: 5px;
    background-color: #fff;
}

.order-hd {
    color: #323233;
    font-size: 14px;
    font-weight: 500;
}

.order-attr {
    margin-top: 5px;
}

.order-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.order-footer button {
    width: 90px;
    height: 26px;
    color: #000;
    font-size: 13px;
    border-radius: 20px;
    border: 1px solid #e01616;
    background-color: #fff;
}

.order-row {
    display: flex;
    align-items: center;
}

.order-fill {
   flex: 1;
}
</style>