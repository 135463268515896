<template>
    <div>
        <!-- <van-cell-group title="收货门店"> -->
            <van-nav-bar left-arrow left-text="返回" title="消息" @click-left="goback"/>
            <div v-show="num==='0'">
                <van-cell v-for="(item,index) in dataList" :title="item.name" is-link @click="shopInfoClick(item)" :key="index"/>     
                      
            </div> 

            <div v-show="num==='1'">
                <van-cell v-for="(item,index) in dataList"  :title="item.title"  :key="index"  is-link @click="toNoticeDetails(item.id)" />     
                      
            </div> 

            <div v-show="num==='2'">
                <van-cell v-for="(item,index) in dataList"   :title="item.title" is-link  :key="index" @click="toNoticeDetails(item.id)"/>
                      
            </div> 
    </div>
</template>
<script>
import {getShop,consignment}from '../tool/api'
export default {
    data(){
        return{
            num:0,
            dataList:[],
        }
    }, 
    mounted(){  
        this.num=this.$route.query.num;   
        if(this.num==='0'){ //0收获门店
            this.getShopList();
        }else if(this.num==='1'){ //1发货要求
            this.getConsignment(1);
        }else{ //2禁运商品
            this.getConsignment(3);
        }     
    },
    methods:{
        goback() {
            history.back()
        },
        shopInfoClick(val){
            this.$router.push({path:'/shopDetails',query: { data:val }});
        },
        imgOrText(val){
            let content="";
            if(val.indexOf('img')>0){
                let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
                let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
                let arr = val.match(imgReg)  //筛选出所有的img
                console.log(arr)
                let srcArr = []
                for (let i = 0; i < arr.length; i++) {
                    let src = arr[i].match(srcReg)
                    // 获取图片地址
                    srcArr.push(src[1]);
                    console.log(src);
                } 
                
            }else{
                return val;
            }
        },
        getShopList(){
            getShop().then(a=>{
                this.dataList=a.data;
            })
        },
        getConsignment(val){
            consignment(val).then(a=>{
                this.dataList=a.data;
            })
        },
        toNoticeDetails(val){
             this.$router.push({path:'/noticeDetails',query: { id:val }});
        }
    }
}
</script>

<style>
.van-nav-bar .van-icon {
    color: #999;
}
.van-nav-bar .van-nav-bar__text {
  color: #999;
}
</style>