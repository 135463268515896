<template>
	<div class="container">
		<van-nav-bar left-arrow left-text="返回" title="充值支付页面" @click-left="goback" />
		<div class="recharge">
			<div class="blance-wrap">
				<span>账号余额</span>
				<div class="blance-row">
					<span class="blance">{{ balance }}日元</span>
					<div @click="goWallet">
						<span>钱包明细</span>
						<van-icon name="arrow" color="#fff" size="12" />
					</div>
				</div>
			</div>

			<div class="tips">充值金额</div>
			<div class="quickRecharge">
				<div class="btnDiv" @click="quickRechargeClick(6000)">
					6000日元
				</div>
				<div class="btnDiv" @click="quickRechargeClick(5000)">
					5000日元
				</div>
			</div>

			<div class="form-wrap">
				<div class="tips">充值金额</div>
				<van-field class="money-input" v-model="money" name="money" placeholder="请输入充值金额" type="digit"
					:rules="[{ required: true, message: '请输入充值金额' }]" />
				<div class="tips">选择支付方式</div>
				<van-radio-group v-model="radio">
					<div class="form-item">
						<span>线下充值</span>
						<van-radio name="1" checked-color="#e01616" />
					</div>
					<div class="form-item">
						<span>微信充值</span>
						<van-radio name="2" checked-color="#e01616" />
					</div>
					<div class="form-item">
						<span>支付宝充值</span>
						<van-radio name="3" checked-color="#e01616" />
					</div>
				</van-radio-group>
			</div>

			<div class="submit-btn" @click="onSubmit">提交</div>

			<div class="tips">充值说明</div>
			<div class="content">
				<ul>
					<li>1.仅限线下充值微信充值和支付宝充值, 充值金额实时到账</li>
					<li>2.账户余额有效期:自充值日起用完即止</li>
					<li>3.诺有其他疑问联系客服</li>
				</ul>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import {
		getBalanceDetails,
		recharge,
		rechargeAliPay,
		balance
	} from '../tool/api'
	import ap from '../tool/ap'
	export default {
		data() {
			return {
				radio: '1',
				money: '',
				balance: 0.00
			};
		},
		created() {
			balance().then(a => {
				this.balance = a.data;
			})
		},
		methods: {
			goback() {
				history.back()
			},
			onSubmit(values) {
				if (this.money === '') {
					Toast.fail("请输入充值金额")
					return
				}
				let form = {
					money: this.money,
					paytype: 'offline',
					method: 'wap'
				}
				//线下
				if (this.radio === '1') {
					form.paytype = 'offline';
					recharge(form).then(a => {
						if (a.code === 1) {
							Toast.success("请于服务员核对!");
							this.$router.push({
								path: "/wallet"
							})
						} else {
							Toast.fail("提交失败,请联系管理员!");
						}
					})
				}
				//微信
				if (this.radio === '2') {
					let that = this;
					form.paytype = 'wechat';
					form.method = 'mp';
					recharge(form).then(a => {
						if (a.code === 1) {
							WeixinJSBridge.invoke('getBrandWCPayRequest', {
								"appId": a.data.appId,
								"timeStamp": a.data.timeStamp,
								"nonceStr": a.data.nonceStr,
								"package": a.data.package,
								"signType": a.data.signType,
								"paySign": a.data.paySign
							}, function(res) {
								if (res.err_msg == "get_brand_wcpay_request:ok") {
									Toast.success("支付成功");
									that.$router.push({
										path: '/wallet'
									});
								}
							})
						} else {
							Toast.fail("提交失败,请联系管理员!");
						}
					});
				}
				//支付宝
				if (this.radio === '3') {
					let that = this;
					form.paytype = 'alipay';
					form.method = 'mp';

					rechargeAliPay(form).then(a => {
						if (a.code === 1) {
							const div = document.createElement('div') // 创建div
							div.innerHTML = a.data // 将返回的form 放入div
							document.body.appendChild(div) // 将上面创建的元素加入到BODY的尾部
							document.forms[0].acceptCharset = 'utf-8';
							var queryParam = '';
							Array.prototype.slice
								.call(
									document
									.querySelectorAll("input[type=hidden]"))
								.forEach(
									function(ele) {
										queryParam += ele.name +
											"=" +
											encodeURIComponent(ele.value) +
											'&';
									});
							let url = document.forms[0].action + '&' + queryParam
							console.log(url);
							ap.pay(url)
						} else {
							Toast.fail("提交失败,请联系管理员!");
						}
					});
				}
			},
			quickRechargeClick(val) {
				this.money = val;
			},

			goWallet() {
				this.$router.push({
					path: "/wallet"
				})
			}
		},
	};
</script>

<style scoped>
	.recharge {
		padding: 15px;
		background-color: #fff;
	}

	.blance-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100px;
		color: #fff;
		font-size: 14px;
		padding: 0 20px;
		border-radius: 10px;
		background-color: #e01616;
	}

	.blance-wrap .blance {
		font-size: 20px;
	}

	.blance-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
	}

	.form-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;
		border-bottom: 1px solid #f2f3f5;
	}

	.form-item span {
		color: #999;
		font-size: 14px;
	}

	.submit-btn {
		height: 44px;
		line-height: 44px;
		border-radius: 22px;
		color: #fff;
		font-size: 16px;
		text-align: center;
		margin-top: 20px;
		background-color: #e01616;
	}


	.content {
		color: #646566;
		font-size: 12px;
	}

	.tips {
		height: 30px;
		color: #000;
		margin-top: 20px;
	}

	.quickRecharge {
		display: flex;
		padding: 10px 0;
		color: #646566;
	}

	.quickRecharge .btnDiv {
		width: 100px;
		height: 50px;
		line-height: 50px;
		margin-right: 20px;
		border-radius: 10px;
		text-align: center;
		border: 1px solid #a1a2a3;
	}

	.money-input {
		border-radius: 5px;
		background-color: #f2f3f5;
	}
</style>
