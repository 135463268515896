<template>
<div id="addressBox">
   <van-nav-bar left-arrow left-text="返回" title="地址" @click-left="goback"/>
     <van-form @submit="onSubmit">
      <InteligentAi @fallBack="fallBackAI"></InteligentAi>
      <van-field v-model="form.receiver" name="姓名" label="姓名" input-align="right" placeholder="请上传身份证"
        :rules="[{ required: true, message: '请上传身份证' }]" disabled/>
      <van-field v-model="form.receiver_phone" name="电话" label="电话" input-align="right" placeholder="请输入电话"
        :rules="[{ required: true, message: '请填写电话' }]"/>
      <van-cell title="地区(省市区)" :value="form.address" is-link @click="areaClick"/>
      <van-field v-model="detailsAddress" name="详细地址" label="详细地址" input-align="right" placeholder="请输入详细地址"
        :rules="[{ required: true, message: '请输入街道、小区、门牌号' }]"/>
      
      <van-action-sheet v-model="show" title="地区选择" closeable >
        <van-area  :area-list="areaList" @confirm="areaConfirm" />
      </van-action-sheet>  
       <van-field v-model="form.idcard" name="身份证号码" label="身份证号码" v-if="idCardIsRead==false" v-show="idCardIsRead==false" :disabled="idCardIsRead"  input-align="right" placeholder="请输入身份证号码"/>
      <UploadIdCard @fallBack="fallBackImage" @fallBackError="fallBackImageError" v-if="isTrue" :idcardbackimage="form.idcardbackimage" :idcardimage="form.idcardimage"></UploadIdCard>
      
      <div style="margin: 16px;margin-bottom:20px">
        <van-button round block type="info" color="#e01616" native-type="submit">提交</van-button>
      </div>
      <van-dialog v-model="mianZeshow" v-if="mianZeshow" title="是否保存该收件人信息？" show-cancel-button   @confirm="addressConfirm" @cancel="addressCancel">
        <van-radio-group v-model="isAgree">
          <div style="text-decoration:underline;font-size:12px;text-align: center;" @click="toMianze">点此查看电子运单契约条款</div>
          <div style="font-size:12px;text-align: center;">如不同意上述条款请点击取消</div>
          <div style="font-size:12px;text-align: center;">收到揽收通知后还需在包裹内选择</div>
        </van-radio-group>
      </van-dialog>

  </van-form>
</div>


</template>
<script> 
import { Toast,Dialog } from 'vant';
import InteligentAi from '../commons/IntelligentAICommon'
import UploadIdCard from '../commons/UploadIdCardCommon'
import {postion,addressInsert,idCardCheck,getUserDetailsById,addressEdit,expressAddressEdit,getReliefInfo} from '../tool/api'
export default {
  data() {
    return {
      isAgree:"0",
      mianZeshow:false,
      idCardIsRead:true,
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      isTrue:false,
      detailsAddress:'',
      expressId:0,
      childId:0,
      message:'',
      fileList:[],
      areaList:{
        province_list: {},
        city_list: {},
        county_list: {}
      },
      //返回的路由
      router:'',
      searchResult: [],
      fileList: [],
      form:{
        receiver:'',
        receiver_phone:'',
        address:''
      },
      //返回的母单主键
      mid:0,
      show:false,
    };
  },  
  created(){
    //获取订单详情传来的母单主键和路由值,方便会跳
    this.mid=this.$route.query.mid;
    this.expressId=this.$route.query.expressId;
    this.router=this.$route.query.router; 
    this.childId=this.$route.query.childId;
    this.getPostion();
    //修改
    if(this.$route.query.id!==undefined) { 
      getUserDetailsById(this.$route.query.id).then(a=>{
        this.form=a.data;       
        let array=this.form.address2.split("-");
        this.form.address=array[0]+"-"+array[1]+"-"+array[2];
        this.detailsAddress=a.data.address3;
        this.isTrue=true;
      })
    }else{
      this.isTrue=true;
    } 
    
  },
  components:{
    InteligentAi,
    UploadIdCard
  },
  methods: { 
    // agreeChange(val){
    //   alert(val);
    //   this.isAgree=val;
    // },
    goback() {
      history.back()
    },
    toMianze(){
      getReliefInfo(1).then(a=>{
         Dialog.alert({
          title: '免责申明',
          message: a.data.content,
        }).then(() => { 
        
        });
      })
    },
    addressCancel(){ 
      this.isAgree="0";
      this.mianZeshow=false;
    },
    addressConfirm(){
      // if(this.isAgree=="0"){
      //   Toast.success("请勾选电子运单契约条款!");
      //   return 
      // }
       let formValue={}; 
       this.form.address3=this.detailsAddress; 
        
       this.mianZeshow=true; 
       if(this.$route.query.id!==undefined) {  
         addressEdit(this.form).then(a=>{
             if(a.code===1){
                 Toast.success("修改成功");
                 if(this.expressId!=0&&this.expressId!=undefined&&this.expressId!=null){
                   formValue.express_id=this.expressId;
                   formValue.address_id=a.data.id;
                  
                    expressAddressEdit(formValue).then(f=>{
                        if(f.code===1){
                          Toast.success("选择成功");
                          this.$router.push({path:'/orderProductDetails',query:{id:this.expressId}});
                        }else{  
                          Toast.fail("选择失败");
                        }
                   })
                   .catch(() => {
                       this.$router.push({path:"/addressList"});    
                   });
                  
                 }else if(this.childId!=0&&this.childId!=undefined&&this.childId!=null){
                   formValue.express_id=this.childId;
                   formValue.address_id=a.data.id;
                       expressAddressEdit(formValue).then(f=>{
                        if(f.code===1){
                          Toast.success("选择成功");
                          this.$router.push({path:'/orderDetails',query:{mid:this.mid}});
                        }else{  
                          Toast.fail("选择失败");
                        }
                      })
                   .catch(() => {
                       this.$router.push({path:"/addressList"});    
                   });
                 }else{
                   this.$router.push({path:"/addressList"});    
                 }
                 
              }else{
                Toast('修改失败');
              }
         })
       }else{
         addressInsert(this.form).then(a=>{
              if(a.code===1){
                if(this.mid!=0&&this.router!=undefined&&this.router!=null&&this.router!=''){ 
                  Toast.success("添加成功");
                  if(this.expressId!=0&&this.expressId!=undefined&&this.expressId!=null){
                    formValue.express_id=this.expressId;
                    formValue.address_id=a.data.id;
                    Dialog.confirm({
                      title: '地址确认',
                      message: '是否该地址为收货地址',
                    })
                    .then(() => {
                        expressAddressEdit(formValue).then(f=>{
                          if(f.code===1){
                            Toast.success("选择成功");
                            this.$router.push({path:'/orderProductDetails',query:{id:this.expressId}});
                          }else{  
                            Toast.fail("选择失败");
                          }
                        })
                    })
                    .catch(() => {
                        this.$router.push({path:"/addressList"});    
                    });
                    
                  }else if(this.childId!=0&&this.childId!=undefined&&this.childId!=null){
                    console.log("进了childId")
                    formValue.express_id=this.childId;
                    formValue.address_id=a.data;
                      Dialog.confirm({
                      title: '地址确认',
                      message: '是否该地址为收货地址',
                    })
                    .then(() => {
                      console.log("提前进来了")
                        expressAddressEdit(formValue).then(f=>{
                          if(f.code===1){
                            Toast.success("选择成功");
                            this.$router.push({path:'/orderDetails',query:{mid:this.mid}});
                          }else{  
                            Toast.fail("选择失败");
                          }
                        })
                    })
                    .catch(() => {
                        this.$router.push({path:"/addressList"});    
                    });
                  }else{
                    this.$router.push({path:"/addressList"});    
                  }
                    //  console.log("mid"+this.mid);
                    //  console.log("router"+this.router); 
                    //  this.$router.push({path:this.router,query:{mid:this.mid}});
                }else{
                    this.$router.push({path:"/addressList"})        
                }
              }else{
                Toast(a.msg);
              }
            })
       }
    },
    fallBackAI(val){
      console.log(val);
      if(val.name===undefined&&val.city===undefined&&val.county===undefined&&val.province===undefined&&val.tel===undefined){
          
      }else{
          this.form.receiver=val.name;
          this.form.receiver_phone=val.tel;
          this.form.address=val.province+"-"+val.city+"-"+val.county;
          this.detailsAddress=val.addressDetail;
          return;
      }

    },
    fallBackImageError(){
      Toast("请手动填写身份证号码信息,谢谢配合!!!");
      this.idCardIsRead=false;
    },
    /**
     * 图片回传
     * **/
    fallBackImage(val){
      this.form.idcardimage=val.positive;
      this.form.receiver=val.userName;
      this.form.idcardbackimage=val.verso;
      this.form.idcard=val.idCard;
      if (!val.isVerso) {
        if(val.idCard==null||val.idCard==undefined||val.idCard=='') {
          Toast("请手动填写身份证号码信息,谢谢配合!!!");
          this.idCardIsRead=false;
        }
      }
    },
    areaConfirm(val){
      this.form.address=val[0].name+"-"+val[1].name+"-"+val[2].name;
      this.show=false;
    },
    getPostion(){
      postion().then(a=>{ 
        this.areaList=a.data;
      })
    },
    areaClick(){
      this.show=true;
    },
    onSubmit() { 
     
      if((this.form.idcardimage===null||this.form.idcardimage===undefined||this.form.idcardbackimage===null||this.form.idcardbackimage===undefined)&&this.idCardIsRead==true){
        Toast("请上传身份证正反面!!");
        return;
      }else{
        // if(this.expressId!=0&&this.expressId!=undefined&&this.expressId!=null){ 
            this.mianZeshow=true;
        // }

        // this.addressConfirm();
      }
      
      // this.form.address+="-"+this.detailsAddress;
      
      
      
    },
    onDelete() {
      Toast('delete');
    },
    //唤起图片
    onSearch(){
      
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: '黄龙万科中心',
            address: '杭州市西湖区',
          },
        ];
      } else {
        this.searchResult = [];
      }
    },
  },
};

</script>
<style scoped> 
  .btn {
    color: #fff;
    padding: 0 6px 1px;
    /* background: #1989fa; */
    border-radius: 5px;
    position: absolute;
    bottom: 2%;
    left: 5%;
  }
</style> 