<template>
    <div class="intDiv"> 
        <div style="border:1px solid #FFFFFF">
            <div style="font-size:13px;padding-left:16px">智能填写</div>
            <van-field v-model="message" rows="3" autosize ref="message" type="textarea"placeholder="粘贴如：王小二 13688668866 河南省郑州市二七区二七广场450000" />
             <van-cell title="单元格" value="内容" size="large">
                <template #title>
                    <van-uploader :max-count="1"  :after-read="afterRead">
                        <van-button  size="small" round type="info" color="#e01616">图片识别</van-button> 
                    </van-uploader>
                </template>
                <template #default> 
                     <van-button   size="small" round type="info"  color="#e01616" native-type="button"  @click="inputChange">识别输入信息</van-button> 
                </template>
            </van-cell>
        </div> 
        <!-- <div class="btn" style="float:left"> -->
           
            <!-- <div  style="font-size:13px;line-height:30px;float:left;padding-left:15px;" @click="inputChange">识别结果</div> 
            <div style="float:right;padding-right:15px">
                <div style="height:30px;margin-right:5px;padding-left:16px;float:left;"><img src="../assets/照相机 (1).png" width="20"></div>
                <van-uploader :max-count="1"  :after-read="afterRead">
                    <span style="font-size:13px;line-height:30px"> 图片识别</span>   
                </van-uploader>
            </div> -->
        <!-- </div>  -->
    </div>
</template>
<style scoped>
.intDiv{
    background: white; 
    padding: 10px 0px;
    color: #646566; 
    margin-top: -1px;
    border: 1px solid #F1F1F1;
}
</style>
<script>
import { Toast } from 'vant';
import {getsmAddress,wordRecognition,uploadFile} from '../tool/api'
export default {
    data(){
        return{
            info:{},
            message:''
        }
    },
    methods:{
        afterRead(file){
            let formData = new FormData()
            file.status = 'uploading';
            file.message = '上传中...';
            formData.append('file', file.file)
            uploadFile(formData).then(a=>{ 
                if(a.code===1){
                    file.status = 'success';
                    file.message = '上传成功';  
                    // this.$emit("fallBack",a.data.url)
                    console.log(a);
                    wordRecognition(a.data.url).then(s=>{
                        if(s.code===1){ 
                            let msg="";
                            for(let context=0;context<s.data.words_result.length;context++){
                                msg+=s.data.words_result[context].words;
                            }
                             getsmAddress(msg).then(a=>{
                                console.log(a);
                                if(a.code===1){
                                    this.$set(this.info, "name", a.data.person);
                                    this.$set(this.info, "tel", a.data.phonenum);
                                    this.$set(this.info, "addressDetail",a.data.detail);
                                    this.$set(this.info, "postalCode", a.data.province_code);
                                    this.$set(this.info, "city",a.data.city);
                                    this.$set(this.info, "county", a.data.county);
                                    this.$set(this.info, "isDefault", true);
                                    this.$set(this.info, "areaCode", a.data.city_code);
                                    this.$set(this.info, "province", a.data.province);
                                    this.$emit("fallBack",this.info);
                                }else{
                                    Toast("识别失败,请查看识别地址是否正确!");c
                                }
                                
                            })
                        }else{
                            Toast.fail("地址识别失败");
                        }
                        
                    })
                }else{
                    file.status = 'fail';
                    file.message = '上传失败';
                }
            })
        },
        inputChange(){ 
            if(this.message!=undefined&&this.message!=null&&this.message!=''){
                getsmAddress(this.message).then(a=>{
                    console.log(a);
                    if(a.code===1){
                        this.$set(this.info, "name", a.data.person);
                        this.$set(this.info, "tel", a.data.phonenum);
                        this.$set(this.info, "addressDetail",a.data.detail);
                        this.$set(this.info, "postalCode", a.data.province_code);
                        this.$set(this.info, "city",a.data.city);
                        this.$set(this.info, "county", a.data.town);
                        this.$set(this.info, "isDefault", true);
                        this.$set(this.info, "areaCode", a.data.city_code);
                        this.$set(this.info, "province", a.data.province);
                        this.$emit("fallBack",this.info);
                    }else{
                        Toast("识别失败,请查看识别地址是否正确!");c
                    }
                    
                })
                
            }
            
        }
    }
}
</script>