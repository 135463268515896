<template>
    <div>
        <van-nav-bar left-arrow left-text="返回" title="订单详情列表" @click-left="goback"/>
        <van-tabs v-model="tabcur" @click="tabChange">
            <van-tab v-for="(item, index) in tabs" :key="index" :title="item.title"/>
        </van-tabs>
        <van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
          <van-list style="margin-bottom: 100px" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" >
            <div class="order-item" v-for="(item,index) in dataList" :key="index">
                <div class="order-row" style="justify-content: space-between;">
                    <span class="order-hd">运单号：{{item.sf_sn}}</span>
                    <span class="order-status">{{item.status_text}}</span>
                </div>
                <div class="order-row">
                    <span class="order-attr order-fill">箱号：{{ item.sn }}</span>
                    <span class="order-attr order-fill">重量：{{ item.money_weight }}</span>
                </div>
                <div class="order-row">
                    <span class="order-attr order-fill">收货人: {{item.receiver}}</span>
                    <span class="order-attr order-fill">时间:{{item.sendtime>0?item.sendtime_text: ''}}</span>
                </div>
                 <span class="order-attr">备注：{{item.remark1==undefined?"":item.remark1}}</span>
                <div class="order-footer">
                  <button v-if="item.status===6" @click="editAddress(item,index)">更改收货地址</button>
                  <button class="address-btn" v-if="item.status>=2&&item.status!=5&&item.status!=6&&item.receiver_phone===''" @click="selectAddress(item,index)">选择收货地址</button>
                  <button @click="goodsInfo(item)">查看包裹信息</button>
                  <button :disabled="item.status===5?true:false" v-show="item.is_ftp!=0"  @click="cancelOrder(item)">取消发货</button>
                  <button @click="queryTrajectory(item.id)">查看物流</button>
                </div>
            </div>
          </van-list>
        </van-pull-refresh>
         
        <van-dialog v-model="show" :title="`单号:`+addressInfo.orderNo">
            <van-cell-group title="收件人信息">
                <van-cell title="收货人" :value="addressInfo.receiver"/>
                <van-cell title="收货地址" :value="addressInfo.shipping_address"/>
                <van-cell title="发货状态" :value="addressInfo.status_text"/>
                <van-cell title="备注" :value="addressInfo.remark1"/>
             </van-cell-group>
           <van-cell-group title="商品详情"> 
                <van-cell :title="item.name+'('+item.goods_sn+')'" v-for="(item,index) in goodsList" :key="index">
                    <template #default>
                        数量:{{item.num}}
                    </template>
                </van-cell> 
           </van-cell-group>
        </van-dialog> 

        <van-dialog v-model="showTel" title="提示" show-cancel-button  message="此取消还未成立,请与sptm联系" @confirm="editStoreIdMethod"></van-dialog>
        <van-dialog v-model="showTel1" title="提示" show-cancel-button  message="是否取消该快递" @confirm="editStoreIdMethod"></van-dialog>
        <!-- <van-dialog v-model="mianZeshow2" title="免责申明" show-cancel-button >
            {{mianzeData}}
        </van-dialog> -->
        <van-dialog v-model="mianZeshow" title="是否确认该收件人信息?" confirmButtonText="同意" show-cancel-button   @confirm="addressConfirm" @cancel="addressCancel">
            <van-radio-group v-model="isAgree">
                <!-- <van-radio name="1"><div style="text-decoration:underline;font-size:12px" @click="toMianze">不同意电子运单契约条款请点取消</div></van-radio>  -->
                <div style="text-decoration:underline;font-size:12px;text-align: center;" @click="toMianze">点此查看电子运单契约条款</div>
                <div style="font-size:14px;text-align: center;">如不同意上述条款请点击取消</div>
            </van-radio-group>
        </van-dialog>
        <van-action-sheet v-model="addressShow" :title="title">
            <!-- <van-cell title="添加地址" is-link /> -->
          <van-address-list v-model="chosenAddressId" :list="list"
            add-button-text="新增地址"                
            @add="onAdd"
            @select="onSelect"
            @edit="onEdit"
            />
        </van-action-sheet>
    </div>
</template>

<style scoped>
*{
    font-size: 12px;
}
</style>
<script>
import { Toast } from 'vant';
import {getOrderChildInfo,expressCancel,getExpressInfo,getReliefInfo,addressList,expressAddressEdit} from '../tool/api'
import { Dialog } from 'vant';
export default {
    inject:['reload'],
    data(){
        return {
            tabcur: 0,
            tabs: [
                {
                    id: 0,
                    title: '全部'
                },
                {
                    id: 1,
                    title: '未上传收件人'
                },
                {
                    id: 2,
                    title: '已传收件人'
                },
                {
                    id: 3,
                    title: '已发货'
                }
            ],
            //是否同意免责
            isAgree:"0",
            //免责数据
            mianzeData:'',
            mianZeshow2:false,
            //免责弹框
            mianZeshow:false,
            mid:0,
            showTel1:false,
            unit:'',
            refreshing: false,
            loading: false,
            finished: false,
            cancelForm:{},
            form:{},
            index:-1,
            trajectoryShow:false,
            show:false,
            dataList:[],
            addressInfo:{},
            showTel:false,
            page:1,
            orderId:0,
            //是否可以取消
            canCancel:false,
            addressShow:false,
            title:'',
            chosenAddressId: '1',
            //商品信息集合
            goodsList:[],
            //收件人信息集合
            list: [],
            disabledList: []
        }
    },
    created(){ 
  
    },
    methods:{
        goback() {
            history.back()
        },
        tabChange() {
            console.log(this.tabcur)
            this.onRefresh()
        },

        onRefresh() {
          this.page = 1
          this.getDataList(true)
            // this.mid=this.$route.query.mid;        
            // getOrderChildInfo(this.mid,this.page, this.tabcur).then(a=>{ 
            //     this.addressInfo.orderNo=a.data.sn;
            //     this.addressInfo.receiver=a.data.receiver;
            //     this.addressInfo.shipping_address=a.data.shipping_address;
            //     this.addressInfo.status_text=a.data.status_text;
            //     this.addressInfo.remark1=a.data.remark1; 
            //     this.dataList=a.data;
            // })
        },
        onLoad() {
          this.getDataList(false)
        },

        getDataList(isRefresh){
          this.mid=this.$route.query.mid;        
          getOrderChildInfo(this.mid, this.page, this.tabcur).then(a => {
            let tempList = a.data
            if (isRefresh) {
              this.refreshing = false
              this.dataList = tempList;
            } else {
              if (tempList.length < 20) {
                this.loading = false
                this.finished = true
              } else {
                this.page += 1
                this.loading = false
                this.finished = false
              }
              this.dataList = this.dataList.concat(tempList)
            }
          })
        },
        editStoreIdMethod(){
            expressCancel(this.cancelForm.id).then(a=>{
                if(a.code===1){
                    this.showTel=false;
                    this.showTel1=false;
                    Toast(a.msg);
                }else{
                    Toast(a.msg);
                }
            })
        },
        cancelOrder(item){            
            this.cancelForm=item;
            console.log(this.cancelForm)
            if(item.is_ftp===1){
                this.showTel=true;
                this.showTel1=false;
            }else{
                this.showTel1=true;
                this.showTel=false;
            }
            
        },
        /**
         * 查看订单信息
         * **/
        goodsInfo(val){  
             this.$router.push({path:'/orderProductDetails',query:{id:val.id}});
        }, 
        toMianze(){
            getReliefInfo(1).then(a=>{
                Dialog.alert({
                    title: '免责申明',
                    message: a.data.content,
                }).then(() => {
                    // expressAddressEdit(this.form).then(a=>{
                    //     if(a.code===1){
                    //         this.addressShow=false;
                    //         val.receiver_phone='1'; 
                    //         Toast.success("选择成功");
                    //         let data=this.dataList[this.index];
                    //         data.status=6;
                    //         data.receiver=val.receiver;
                    //         this.$set(this.dataList,this.index,data);
                    //         this.mianZeshow=false;
                    //         // this.reload();
                    //     }else{
                    //         Toast.fail(a.msg);
                    //     }
                    // }) 
                })
                //  this.mianZeshow2=true;
                //  this.mianzeData=a.data.content;
             })

        },
        addressCancel(){
            this.mianZeshow=false;
            this.isAgree="0";
        },
        addressConfirm(){
        //   if(this.isAgree=='0'){
        //       Toast("请勾选运单契约条款");
        //       return;
        //   }
          expressAddressEdit(this.form).then(a => {
            if(a.code===1) {
              this.addressShow=false;
              // val.receiver_phone='1'; 
              Toast.success("选择成功");
              let data=this.dataList[this.index];
              data.status=6;
              // data.receiver=val.receiver;
              this.$set(this.dataList,this.index,data);
              this.mianZeshow=false;
              this.getDataList();
              // this.reload();
            }else{
              Toast.fail(a.msg);
            }
          }) 
        },
        onSelect(val){
            this.mianZeshow=true;
            this.form.address_id=val.id;
                    
        },
        onAdd(){
             let router=this.$route.path;
             let mid=this.$route.query.mid;
             //,query:{router:path}
             this.$router.push({ path:"/address",query:{mid:mid,router:router,childId:this.form.express_id}});
        },
        editAddress(val,index) {
          this.$router.push({path:'/addressList',query:{id:val.id}});
        },

        selectAddress(val,index){ 
          if(val.status===5){
              Toast("快递已取消,无法修改收货地址");
              return;
          } 
          this.index=index;
          this.form.express_id=val.id; 
          addressList().then(a=>{
            this.list=[];
            for(let i=0;i<a.data.length;i++){
            this.list.push({
              id:a.data[i].id,
              name:a.data[i].receiver,
              address:a.data[i].address,
              tel:a.data[i].receiver_phone
              });
            }
          }) 
          this.addressShow=true;
          this.show=false;  
        },
        /**
         * 查看物流
         * **/
        queryTrajectory(val){
             this.$router.push({path:'/trajectory', query: {id:val}});             
        },
        onEdit(item, index){
            this.$router.push({ path:"/address",query:{id:item.id}})
        },
    }
}
</script>

<style scoped>
.order-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    color: #646566;
    font-size: 13px;
    border-radius: 5px;
    background-color: #fff;
}

.order-hd {
    color: #323233;
    font-size: 14px;
    font-weight: 500;
}

.order-status {
    color: #646566;
    font-size: 10px;
}

.order-row {
    display: flex;
    align-items: center;
    color: #646566;
    font-size: 10px;
}

.order-fill {
   flex: 1;
}

.order-attr {
    color: #646566;
    font-size: 13px;
    margin-top: 5px;
}

.order-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
}

.order-footer button {
    height: 24px;
    color: #646566;
    font-size: 12px;
    padding: 0 10px;
    margin-left: 10px;
    border: 1px solid #DE1717;
    border-radius: 24px;
    background-color: #fff;
}

.order-footer .address-btn {
    color: #fff;
    background-color: #e01616;
}
</style>
  