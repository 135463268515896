<template>
    <div>
        <van-cell-group>
            <van-cell :title="item.title" :value="item.value"  v-for="(item,index) in list" :key="index"/>            
        </van-cell-group>
    </div>
</template>
<script>
export default {
    data(){
        return{
            list:[{title:"重量大于50KG",value:'收费15000'},{title:"重量大于20KG",value:'收费12000'},{title:"测试",value:'测试'},{title:"测试",value:'测试'},{title:"测试",value:'测试'},{title:"测试",value:'测试'},{title:"测试",value:'测试'}],
        }
    }
}
</script>