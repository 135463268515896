<template>
    <div class="container">
      <van-nav-bar left-arrow left-text="返回" title="包裹详情" @click-left="goback"/>
      <div class="form-wrap">
        <!-- <van-contact-card type="edit" :name="currentContact.name" :tel="currentContact.tel" @click="onEdit"/> -->
        <div class="card-wrap">
          <div class="card-item">
            <span class="card-no">顺丰单号：{{ form.sf_sn }}</span>
          </div>
          <div class="card-item">
            <span class="card-fill">姓名：{{ form.receiver }}</span>
            <span class="card-fill">电话：{{ form.receiver_phone }}</span>
          </div>
          <div class="card-item">
            <span>地址：{{ form.shipping_address }}</span>
          </div>
          <div class="card-item">
            <span class="card-fill">计费金额：{{ payType==1?form.total_money_rmb:form.total_money }}</span>
            <span class="card-fill">计费重量：{{ form.money_weight }}KG</span>
          </div>
           <div class="card-item">
            <span>揽收时间：{{ form.createtime }}</span>
          </div>
           <div class="card-item">
            <span>发货时间：{{ form.sendtime }}</span>
          </div>
          <div class="card-item">
            <span>备注：</span>
            <span>{{ form.remark }}</span>
          </div>
        </div>
      </div>
       

        <van-card :num="item.num"   v-for="(item,index) in goods" :key="index" :desc="`条形码:`+item.goods_sn" :title="item.name" thumb="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1568267523,2209717438&fm=26&gp=0.jpg">
        </van-card>        
    </div>
</template>

<script>
import { Toast } from 'vant';
import {getExpressInfo,getOrderInfo,getExpressDetails} from '../tool/api'
export default {
    data(){
        return{
            disabled:false,
            currentContact:{
                id:0,
                name:'',
                tel:''
            },
            //是否支付 1未支付
            isPay:0,
            expressStatus:0,
            //1.在线支付 2.余额支付 3.线下支付
            payType:2,
            units:'日元',
            actions: [{name:'线下支付'},{ name: '余额' }, { name: '支付宝' }],
            payTypeShow:false,
            show:false,
            form:{},
            list:[],
            goods:[]
        }
    },
    created(){
        var mid=this.$route.query.id;   
        getExpressDetails(mid).then(a=>{ 
            this.expressStatus=a.data.status;
            this.currentContact.name=a.data.receiver;
            this.currentContact.tel=a.data.receiver_phone;
            this.currentContact.id=a.data.user_address_id;
            this.form.total_money=Math.round(a.data.total_money)+this.units;
            this.form.total_money_rmb=Math.round(a.data.total_money_rmb)+this.units;
            this.form.money_weight=a.data.money_weight;
            this.form.remark=a.data.remark1;
            this.form.status=a.data.status;
            this.form.sendtime=a.data.sendtime===0?'':a.data.sendtime;
            this.form.is_ftp=a.data.is_ftp;
            this.form.is_down=a.data.is_down;
            this.form.createtime=this.format(a.data.createtime);
            this.form.receiver=a.data.receiver;
            this.form.receiver_phone=a.data.receiver_phone;
            this.form.shipping_address=a.data.shipping_address;
            this.form.sf_sn=a.data.sf_sn;
            this.form.id=a.data.id;
             console.log("订单详情============================") 
             console.log(this.form); 
             console.log("订单详情============================")
           
            getExpressInfo(mid).then(s=>{
                console.log("商品信息============================")
                this.goods=s.data
                console.log(s);
                console.log("商品信息============================")
            });
            getOrderInfo(a.data.express_m_id).then(s=>{
                this.isPay=s.data.status;
                if(s.data.paytype===3){
                    this.units='日元';
                }else if(s.data.paytype===2){
                    this.units='日元';
                }else{
                    this.units='人民币';
                }
            })
        })
    }, 
    methods:{
      goback() {
        history.back()
      },
        copy(){
            const _this = this
            var clipboard = new this.clipboard('.cobyOrderSn');
            clipboard.on('success', function (val) {
                _this.disabled = true;
                setTimeout(() => {
                  _this.disabled = false;
                    // 销毁实例防止多次触发
                  clipboard.destroy();
                }, 3000)
                Toast.success('复制成功')
            })
            clipboard.on('error', function () {
              Toast.fail('复制失败，请手动复制')
            }) 
        },
        add0(m){return m<10?'0'+m:m },
        format(val){
            // return new Date(parseInt(val) * 1000).toLocaleString().replace(/:\d{1,2}$/,' '); 
            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(parseInt(val) * 1000);
            var y = time.getFullYear();
            var m = time.getMonth()+1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            return y+'-'+this.add0(m)+'-'+this.add0(d)+' '+this.add0(h)+':'+this.add0(mm)+':'+this.add0(s);
        },
         onEdit(){
             if(this.expressStatus==3){
                Toast("快递已发货,无法修改收货地址");
                return;
             }else if(this.isPay==1){
                 Toast("在支付前,无法选择地址,请先支付费用!");
                 return;
             }else if(this.form.is_down!=0){
                 Toast("快递已发出,无法修改收货地址");
                 return;
             }else if(this.expressStatus==5){
                 Toast("快递已取消,无法修改收货地址");
                 return;
             }else{
                 this.$router.push({path:'/addressList',query:{id:this.form.id}});
             }
            
         },  
    }
}
</script>

<style scoped>
.container {
  padding: 12px;
}

.form-wrap {
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.card-wrap {
  padding: 12px;
}

.card-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: #999;
  font-size: 13px;
}

.card-item span {
  line-height: 20px;
}

.card-fill {
  flex: 1;
}

.card-no {
  color: #333;
  font-size: 14px;
  font-weight: 500;
}
</style>