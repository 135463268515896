<template>
  <div class="container">
    <van-nav-bar left-arrow left-text="返回" title="免责说明" @click-left="goback"/>
    <div class="con-wrap">
      <div class="title">{{ data.title }}</div>
      <div class="content" v-html="data.content"></div>
    </div>
  </div>
</template>
<script>
import { getReliefInfo } from "../tool/api";
export default {
  data() {
    return {
      id: "",
      data: null,
    };
  },
  created() {
    this.id = this.$route.params.id;
    getReliefInfo(this.id).then((a) => {
      this.data = a.data
    });
  },
  methods: {
    goback() {
      history.back()
    }
  }
};
</script>

<style scoped>
.container {
  
}
.con-wrap {
  padding: 20px;
}
.title {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.content {
  margin-top: 10px;
}
</style>

<style>
.van-nav-bar .van-icon {
    color: #999;
}
.van-nav-bar .van-nav-bar__text {
  color: #999;
}
</style>