<template>
    <div :style="{height: height}">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :style="{height: height}">
            <van-list>
            <div :style="{height: inputDivHeight}">
                <div v-for="(item,index) in msgList">
                    <div class="to" v-if="item.to===1">
                        <img src="../assets/service.png" alt="" width="50">
                        <div class="content">{{item.msg}}</div>
                    </div> 
                    <div class="form" v-if="item.to===0">
                        <img src="https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1028905801,1902977906&fm=26&gp=0.jpg" alt="" width="50">
                        <div class="content">{{item.msg}}</div>
                    </div> 
                    <div  v-if="item.to===-1">
                        
                    </div> 
                </div>
            </div>
            </van-list>
            </van-pull-refresh>
            <div class="inputDiv" >
                 <van-search v-model="value" show-action   background="#969797" >
                    <template #left-icon>
                         <i></i>
                    </template>
                    <template #action>
                        <div style="border-radius:10px;background-color: rgb(82, 162, 231);width:50px;   text-align: center; " @click="send">发送</div>
                    </template>
                 </van-search>
            </div>
      
    </div>
</template>
<style scoped>
.to{
    padding: 10px;
    vertical-align:middle;
    width:100%;
    height: 50px;
    
}
.to .content{
     padding:10px 15px;
     border-radius: 40px;
     background-color: rgb(253, 254, 255);
     float: left;
}
.inputDiv{
    width: 100%;
    background-color: rgb(150, 151, 151);    
}
.time{
    text-align: center; 
    width:100%;
    font-size: 10px;
    color: rgb(150, 151, 151);    
    line-height: 20px;
}
.to img{
    margin-right: 20px; 
    float: left;
} 
.form{
    padding: 10px;
    vertical-align:middle;
    width:100%;
    height: 50px;
    
}
.form img{
    margin-right: 20px; 
    float: right;
} 
.form .content{
     padding:10px 15px;
     border-radius: 40px;
     margin-right: 20px; 
     
     background-color: rgb(82, 162, 231);
     float: right;
}
</style>
<script>
import { Toast } from 'vant';
import {ask_kf,sendMsg,getimlogList,getimlogListTop} from '../tool/api'
export default {
  data() {
    return {
      count: 0,
      msgList:[{to:-1,msg:'123123'}],
      height:0,
      value:'',
       loading: false,
      finished: false,
      kfId:'',
      page:0,
      tarHeight:75, 
      inputDivHeight:0,
      isLoading: false,
      isRevice:false,
      style:{

      }
    };
  },
  created(){
      this.finished=true;
    //   this.height=window.screen.height-this.tarHeight+'px';
    //   this.inputDivHeight=window.screen.height-this.tarHeight-75+'px'
    this.height=window.screen.height-54+'px';
    this.inputDivHeight=window.screen.height-54+'px'
      this.getAskKf();
      
  },
  methods: {
    send(){
        sendMsg(this.kfId,this.value).then(a=>{
            this.msgList.push({to:0,msg:this.value});  
            this.isRevice=true;
        })
    },

    getAskKf(){
        let kfIdStr=localStorage.getItem("keId")
        if(this.kfId!=null&&this.kfId!=undefined&&this.kfId!=''){
            this.kfId=kfIdStr;
             setInterval(this.getFirst, 1000);
        }else{
            ask_kf().then(a=>{
                this.kfId=a.data.service_user_id;
                // this.$store.state.kfId=a.data.service_user_id;      
                localStorage.setItem("keId",a.data.service_user_id);
                 setInterval(this.getFirst, 1000);
            })
        } 
       
    },
    onRefresh() { 
        this.isLoading = false;
           // 加载状态结束
        this.loading = false;   
        this.page+=1
        getimlogList(this.kfId,this.page).then(a=>{ 
            a.data.forEach(element => {
                let obj={};
                let userId=sessionStorage.getItem("userId");
                if(element.fromid==userId){
                    obj.to=0;    
                }else{
                    obj.to=1;
                }
                obj.msg=element.content;
                this.msgList.unshift(obj);
                  this.finished = true;
            }); 
            
        })
    },
    getFirst(){
        if(this.isRevice===true){
            getimlogListTop(this.kfId,1).then(a=>{
                let isExit=0;
                for(let i=0;i<this.msgList.length;i++){
                    if(this.msgList[i].id==a.data.id){
                        isExit=1;
                    }
                }
                
                if(isExit==0){
                    this.msgList.push({to:1,msg:this.value,id:a.data.id}); 
                } 
            })
        } 
    }
  },
};
</script>