<template>
  <div class="container"> 
    <van-nav-bar left-arrow left-text="返回" title="地址列表" @click-left="goback"/>
    <div class="address-wrap">
      <div class="address-item" v-for="item in address" :key="item.id" @click="selectAddress(item)">
        <span class="address-area">{{ item.address2 }}</span>
        <span class="address-details">{{ item.address3 }}</span>
        <div class="address-row">
          <span style="margin-right: 20px;">{{ item.receiver }}</span>
          <span>{{ item.receiver_phone }}</span>
        </div>
        <div class="address-footer">
          <button class="edit-button" @click="editAddress(item)">编辑</button>
          <button class="delete-button" @click="removeAddress(item)">删除</button>
        </div>
      </div>
    </div>

    <div class="address-add" @click="onAdd">新增地址</div>

    <van-dialog v-model="mianZeshow" title="是否确认该收件人信息?" show-cancel-button   @confirm="addressConfirm" @cancel="addressCancel">
      <van-radio-group v-model="isAgree" style="margin-left:3rem">
        <van-radio name="1">
          <div style="text-decoration:underline;font-size:12px;text-align: center;" @click="toMianze">点此查看电子运单契约条款</div>
          <div style="font-size:14px;text-align: center;">如不同意上述条款请点击取消</div>
        </van-radio> 
      </van-radio-group>
    </van-dialog>
  </div>
</template>
<script> 
import { Toast,Dialog } from 'vant';
import {addressList,expressAddressEdit,addressDel,getReliefInfo} from '../tool/api'
export default {
  data() {
    return {
      isAgree:"0",
      mianZeshow:false,
      expressId:0,
      show:false,
      form:{},
      chosenAddressId: '1',
      address: []
    };
  },
  mounted(){
    this.expressId=this.$route.query.id===undefined?0:this.$route.query.id;
  },
  created(){
    this.getAddressList();
  },
  methods: {
    goback() {
      history.back()
    },
    addressCancel(){
      this.isAgree="0";
      this.mianZeshow=false;
    },
    onAdd() {
       this.$router.push({name: 'Address',query:{expressId:this.expressId}});
    },
    toMianze(){
      getReliefInfo(1).then(a=>{
         Dialog.alert({
          title: '免责申明',
          message: a.data.content,
        }).then(() => {
          // on close
        });
      })
    },
    //地址确认
    addressConfirm(){
      if(this.isAgree=="0"){
        Toast("请勾选同意免责申明!");
        return;
      }
      expressAddressEdit(this.form).then(a=>{
        if(a.code===1){
            // this.$router.push({path:'/orderProductDetails',query:{id:this.expressId}});
            this.$router.push({path:'/orderDetails'});
        }else{
          Toast.fail(a.msg);
        }
      }) 
    },

    getAddressList(){
      addressList().then(a=>{
        this.address = a.data
      })
    },

    selectAddress(item) {
      if(this.expressId!== 0) {
        this.form.express_id = this.expressId
        this.form.address_id = item.id
        this.mianZeshow = true
      }
    },

    editAddress(item) {
      this.$router.push({ path:"/address",query:{id:item.id,expressId:this.expressId}})
    },

    removeAddress(item) {
      Dialog.confirm({title: '提示', message: '是否确认删除当前地址',}).then(() => {
        addressDel(item.id).then(a=>{
          if(a.code === 1) {
            Toast.success("删除成功");
            this.getAddressList();
          } else {
            Toast.fail("删除失败");
          }
        })
      }).catch(() => {

      })
    }
  },
};

</script>  
<style scoped>
.container {
  position: relative;
}
.address-wrap {
  padding: 0 10px;
}
.address-item {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0 15px;
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
}

.address-area {
  color: #333;
  font-size: 14px;
}

.address-details {
  color: #999;
  font-size: 12px;
  margin-top:6px
}
.address-row {
  display: flex;
  align-items: center;
  margin-top:6px
}

.address-row span {
  color: #999;
  font-size: 12px;
}

.address-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    margin-top: 15px;
    border-top: 1px dashed #f2f3f5;
}

.address-footer button {
    width: 66px;
    height: 26px;
    border: 0;
    border-radius: 13px;
    font-size: 12px;
}
.edit-button {
  margin-right: 15px;
  color: #999;
  border: 1px solid #eee !important;
  background: #fff;
}

.delete-button {
  color: #fff;
  background: red;
}

.address-add {
  position: fixed;
  left: 15px;
  right: 15px;
  bottom: 15px;
  z-index: 999;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  background: red;
}

  .van-button--danger{
      color: #fff;
      background-color: #1989fa;
      border: 1px solid #1989fa;
  }
  .van-address-list__bottom{
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
      box-sizing: border-box;
      width: 100%;
      padding: 8px 16px;
      padding-bottom: constant(safe-area-inset-bottom);
      height: 70px;
      padding-bottom: env(safe-area-inset-bottom);
      background-color: #fff;
  }
 .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .van-icon-edit{
    display:none;
  }
 /deep/ .van-address-list .van-radio-group .van-address-item .van-cell .van-cell__value .van-radio .van-radio__icon{
    display: none;
  } 
  .block {
    width: 120px;
    height: 120px;
    background-color: #fff;
  }
</style>