<template>
    <!-- 预约取货 -->
    <div style="padding-bottom:10rem">
        <van-cell-group>
            <van-cell title="门店" @click="shopShowClick" is-link :value="shopName"/>
            <van-cell title="预约时间" @click="timeClick" is-link :value="form.appointmenttime"/> 
            <van-field v-model="form.predict_weight" type="digit" input-align="right" label="预估货量(KG)" placeholder="请输入预估货量" /> 
            <van-field v-model="form.phone" label="联系电话" type="digit" input-align="right" placeholder="请输入联系电话" />
            <van-field v-model="form.address" label="详细地址" input-align="right" placeholder="请输入详细地址" />
            <van-field v-model="form.remarkcontent"  input-align="right"   label="备注" placeholder="" />  
            <uploadImage @fallBack="imgFallBack"></uploadImage> 
        </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" color="#e01616" @click="submitClick">提交</van-button>
          </div>
        <van-action-sheet v-model="shopShow" :actions="actions" @select="onSelect"  />
        <van-action-sheet v-model="timeShow" title="请选择预约时间">
             <van-datetime-picker v-model="form.appointmenttime"  type="datetime"  :min-date="minDate" :max-date="maxDate" @cancel="timeCancel" @confirm="timrConfirm"/>
        </van-action-sheet>
        <van-dialog v-model="takeCareShow" title="注意事项" show-cancel-button>
            <div style="padding-left:3.5rem">
                <ul>
                    <li>上门取件:起步费用8块钱</li>
                </ul>
            </div> 
        </van-dialog>

    </div>
</template>
<script>
import { Toast } from 'vant';
import uploadImage from '../commons/UploadImageCommon'
import {getShop,saveSubscribe} from  '../tool/api'
export default {
    components:{
        uploadImage
    },
    data(){
        return{
             takeCareShow:false,
             minDate: new Date(),
             maxDate: new Date(2050, 10, 1),
             shopName:'',
          
             timeShow:false,
             shopShow:false,
             currentDate: new Date(),
             actions: [
                { name: '东京',value:0 },
                { name: '冲腾',value:1 },
                { name: '川名', value:2 },
            ],
            form:{
                predict_weight:0,
                estimatedTime:''
            }
        }
    },
    created(){
        this.getShopIndex();
        
    },
    methods:{
        getShopIndex(){
            getShop().then(a=>{
                this.actions=a.data;
            })
        }, 
        imgFallBack(val){
            this.form.appointmentimage=val;
        },
        timeCancel(){
            this.timeShow=false;
        },
        timrConfirm(val){ 
            let year = val.getFullYear()
            let month = val.getMonth() + 1
            let day = val.getDate()
            let hour = val.getHours()
            let minute = val.getMinutes()
            if (month >= 1 && month <= 9) { month = `0${month}` }
            if (day >= 1 && day <= 9) { day = `0${day}` }
            if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
            if (minute >= 0 && minute <= 9) { minute = `0${minute}` } 
            this.form.appointmenttime  = `${year}-${month}-${day} ${hour}:${minute}`//=this.timeFormat(val);
            this.timeShow=false;
        },
        timeFormat(time) { // 时间格式化 2019-09-08
            let year = time.getFullYear();
            let month = time.getMonth() + 1;
            let day = time.getDate();
            let hour = time.getHours();
            return year + '-' + month + '-' + day + '-'+hour
        },
        submitClick(){
            //如果检测是否下过单,有就不弹,无反之
            // this.takeCareShow=true;
            console.log(this.form);
            saveSubscribe(this.form).then(a=>{
                if(a.code===1){ 
                    Toast("预约成功");
                       this.$router.push({path:'/subscribeList'});
                }else{
                    Toast("预约失败");
                }
            })
        },
        takeCareClick(){
            this.takeCareShow=true;
        },
        timeClick(){
            this.timeShow=true;
        },
        onSelect(val){
            debugger
            this.shopName=val.name;
            this.form.store_id=val.id;
            this.shopShow=false;
        },
        shopShowClick(){
            this.shopShow=true;
        }
    }
}
</script>