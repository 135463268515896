<template>
    <div>
        <van-nav-bar left-arrow left-text="返回" title="门店详情" @click-left="goback"/>
        <van-cell title="名称" :value="data.name"/>
        <van-cell title="营业时间" :value="data.businesstime"/>
        <van-cell title="休息日" :value="data.restday"/>
        <van-cell title="位置">
            <template #label>
                {{data.address}}
            </template>
            <template #default>
                <van-button type="default" class="cobyOrderSn"   data-clipboard-action="copy" :data-clipboard-text="data.address" :disabled="disabled" @click="copy" >复制</van-button>
            </template>
        </van-cell>
        <van-cell title="联系人"  :value="data.contact_person" />    
        <van-cell title="联系电话"  :value="data.contact_person" />        
    </div>
</template>
<script>
import { Toast } from 'vant';
export default { 
    data(){
        return{
            disabled: false,
            data:null
        }
    },
    methods:{
        goback() {
            history.back()
        },
      copy(){
         const _this = this
          var clipboard = new this.clipboard('.cobyOrderSn');
           clipboard.on('success', function (val) {
	        _this.disabled = true
	        setTimeout(() => {
	          _this.disabled = false
	          // 销毁实例防止多次触发
	          clipboard.destroy()
	        }, 3000)
	        Toast.success('复制成功')
	      })
	      clipboard.on('error', function () {
	        Toast.fail('复制失败，请手动复制')
	      }) 
      }
    },
    created(){
          this.data=this.$route.query.data;
    }
}
</script>

<style>
.van-nav-bar .van-icon {
    color: #999;
}
.van-nav-bar .van-nav-bar__text {
  color: #999;
}
</style>