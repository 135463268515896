<template>
    <div>
      <van-nav-bar left-arrow left-text="返回" title="个人详情" @click-left="goback"/>
      <van-cell-group>
        <van-cell title="头像">
          <template #right-icon>
            <van-image  round width="2rem" height="2rem" :src="headerImage"/>
          </template>
        </van-cell>
        <van-cell title="昵称" :value="userName" />
         <!-- <van-field v-model="form.address"   label="网店Id" input-align="right" placeholder="请输入网店的id" /> -->
        <!-- <van-cell title="性别" :value="sexShow"  is-link @click="show=true"/>                  
        <van-cell title="生日"  is-link :value="form.birthday"  @click="birthdayShow=true"/> -->
        <!-- <van-cell title="地区" @click="areaShow=true" :value="form.area"/> -->
         
      </van-cell-group>
      <!-- <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
      <van-action-sheet v-model="birthdayShow" >
            <van-datetime-picker v-model="currentDate" type="date"  :min-date="minDate" @cancel="onCancel" @confirm="onConfirm" />
      </van-action-sheet>
        <van-action-sheet v-model="areaShow"  >
            <van-area title="选择区域" :area-list="areaList" value="110101" @cancel="onAreaCancel" @confirm="onAreaConfirm" />
      </van-action-sheet>   -->
      
    </div>
</template>
<script> 

export default {
  data() {
    return {
      fileList:[],
      userName:sessionStorage.getItem("nickname"),
      headerImage:sessionStorage.getItem("avatar"),
      show: false,
      birthdayShow:false,
      sexShow:'男',
      areaShow:false,
      actions: [{ name: '男',value:'0'}, { name: '女' ,value:'1'}],
      form:{
          tel:'',
          sex:0,
          area:'',
          birthday:'未设置',
          userId:'12345',
          userName:'陈志佳',
          shopUserId:'12345',
          userImage:sessionStorage.getItem("")

      },
      minDate: new Date(1970, 0, 1), 
      username: '',
      password: '',
      currentDate: new Date(),
      areaList:{
        province_list: {
            110000: '北京市',
            120000: '天津市'
          },
          city_list: {
            110100: '北京市',
            110200: '县',
            120100: '天津市',
            120200: '县'
          },
          county_list: {
            110101: '东城区',
            110102: '西城区',
            110105: '朝阳区',
            110106: '丰台区',
            120101: '和平区',
            120102: '河东区',
            120103: '河西区',
            120104: '南开区',
            120105: '河北区',
          }
        }
    };
  },
  methods: {
    goback() {
      history.back()
    },
    afterRead(){

    },
    onSelect(val){
      //alert(val.value)
      this.sexShow=val.name;
      this.show=false;
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    onConfirm(val){
      
        this.form.birthday=this.timeFormat(val);
        this.birthdayShow=false;
    },
    timeFormat(time) { // 时间格式化 2019-09-08
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        return year + '年' + month + '月' + day + '日'
    },
    onCancel(){ 
        this.birthdayShow=false;
    },
    onAreaCancel(){
        this.areaShow=false;
    },
    onAreaConfirm(val){
      this.areaShow=false;
      let area="";
      for(let i=0;i<val.length;i++){
          area+=val[i].name+",";
      }
      this.form.area=area.substring(0,area.length-1);
    }
  },
};
</script>

<style>
.van-nav-bar .van-icon {
    color: #999;
}

.van-nav-bar .van-nav-bar__text {
  color: #999;
}

</style>