<template>
    <!-- 支付页面 -->
    <div>
        <van-nav-bar left-arrow left-text="返回" title="商品支付" @click-left="goback"/>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> 
          <van-card :title="'订单单号:'+form.sn" thumb="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1568267523,2209717438&fm=26&gp=0.jpg">
            <template #desc>
              <ul class="details">
                <li style="margin:0.2rem 0.2rem 0rem 0rem">计费重量:{{form.money_weight}}KG </li>
                <li style="margin:0.2rem 0.2rem 0rem 0rem">揽收时间:{{form.addtime_text}}</li>
                <li style="margin:0.2rem 0.2rem 0rem 0rem">包裹数量:{{form.express_count}}</li> 
              </ul>  
            </template>
          </van-card>
            <van-divider dashed>订单详情</van-divider>
            <van-cell-group>
              <van-cell :title="'箱号:'+item.sn" value="详情" is-link @click="clickChild(item.id)" v-for="(item,index) in list" :key="index">
                <template #label>
                  <ul class="details">
                    <li style="margin:0.2rem 0.2rem 0rem 0rem">计费重量:{{item.money_weight}}KG</li> 
                    <li style="margin:0.2rem 0.2rem 0rem 0rem">金额:{{unit==0?Math.round(item.total_money)+`日元`:Math.round(item.total_money_rmb)+`人民币`}} </li> 
                    <li style="margin:0.2rem 0.2rem 0rem 0rem">备注:{{item.remark1}} </li> 
                  </ul>  
                </template>
              </van-cell> 
            </van-cell-group>

            <van-radio-group v-model="payName"> 
              <van-cell-group title="支付方式">
                <van-cell :title="item.price==''?item.name:item.name+'('+item.price+')'" clickable @click="onSelect(item.name)" v-for="(item,index) in actions" :key="index">
                <template #right-icon>
                    <van-radio :name="item.name" />
                </template>
                </van-cell> 
              </van-cell-group>
            </van-radio-group>

            <van-cell-group style="padding-bottom:132px">
              <van-cell title="计费金额" :value="unit==0?Math.round(form.total_money)+`日元`:Math.round(form.total_money_rmb)+'人民币'" />
              <van-cell title="计费总重量" :value="(form.money_weight)+`KG`" />            
            </van-cell-group>
            <van-action-sheet v-model="payTypeShow" :actions="actions" @select="onSelect" />
            <!-- -->
            <van-submit-bar  button-text="支付金额" @submit="onSubmit" :price="unit==0?Math.round(form.total_money*100):Math.round(form.total_money_rmb*100)" > 
                <!-- <template #top>
                    <div style="padding:10px 0px 0px 25px;font-size:14px">
                        <span>合计费用:<span style="color:red">￥{{unit==0?Math.round(form.total_money):Math.round(form.total_money_rmb)}}</span></span>
                    </div>                    
                </template>
                <template #default> 
                    <van-checkbox v-model="exemptionChecked">我同意</van-checkbox><span class="exemption" @click="exemption">《免责条款》</span>
                    
                </template> -->
            </van-submit-bar>
        </van-pull-refresh>
    </div>
</template>
<style scoped>
.exemption{
    text-decoration:underline
}
.van-submit-bar__bar{
    height: 73px;
} 
</style>
<script>
import wx from "weixin-js-sdk";
import { Toast,Dialog } from 'vant';
import ap from '../tool/ap'
import {getOrderInfo,getOrderChildInfo,pay,balance,pay2} from '../tool/api'
export default {
    data(){
        return{ 
            exemptionChecked:false,
            isLoading: false,
            //0日元 1人民币
            unit:0,
            storeId:0,
            payName:'余额',
            actions: [{ name: '余额', price:0 }, { name: '微信', price:0 }, { name: '支付宝', price:0 }],
            // actions: [{name:'线下支付',price:''},{ name: '余额',price:0 }, { name: '微信',price:0 }, { name: '支付宝',price:0 }],
            payTypeShow:false,
            show:false,
            form:{},
            list:[],
            goods:[],
            payType:1,
        }
    },
    mounted(){ 
        var mid=this.$route.query.mid; 
        console.log(mid)
        getOrderInfo(mid).then(a=>{
            console.log(a)
            if(a.data.status===2||a.data.status===3){
                 this.$router.push({path:'/orderList'});
            } 
            this.storeId=a.store_id;
            if(this.storeId!=0){
                // this.actions[0].enable=false;
                // this.actions[1].enable=true;
                // this.actions[2].enable=true;
                // this.actions[3].enable=true;
            }else{
                // this.actions[0].enable=false;
                // this.actions[1].enable=false;
                // this.actions[2].enable=false;
                // this.actions[3].enable=false;
            }
            
            this.form=a.data;
            // this.actions[2].price=a.data.total_money_rmb;
            console.log("price");
            console.log(this.actions[2].price);
            getOrderChildInfo(mid,0).then(c=>{
                this.list=c.data; 
            })
        })
    },
    created(){
        balance().then(a=>{
            this.actions.forEach(f=>{
                if(f.name=='余额'){
                    f.price=a.data;
                }
            }) 
        });   
    },  
    methods:{
        goback() {
            history.back()
        },
        exemption(){
             this.$router.push({path:'/statement'});
        },
         onRefresh() {
            var mid=this.$route.query.mid; 
            getOrderInfo(mid).then(a=>{ 
                this.isLoading=false;
                if(a.data.status===2||a.data.status===3){
                    this.$router.push({path:'/orderList'});
                }  
            })
         },
         onSelect(item) { 
             if(item=='余额'){               
                this.payType=1;
                this.payName="余额";
                this.unit=0; 
             }else if(item=='微信'){ 
                this.payType=0;
                 this.unit=1;
                this.payName="微信"; 
             }else if(item=='支付宝'){ 
                this.payType=3;
                 this.unit=1;
                this.payName="支付宝"; 
             }else{
                this.payType=2;
                this.unit=0;
                this.payName="线下支付"; 
             } 
        },
        clickChild(val){ 
            this.$router.push({path:'/orderProductDetails',query:{id:val}});
        },
        onSubmit(){ 
            
            Dialog.confirm({
                title: '支付',
                message: '请确认订单信息',
            })
            .then(() => {
                if(this.payType===1){
                        pay(this.form.id,1).then(a=>{
                            if(a.code===1){
                                Toast("支付成功");
                                this.$router.push({path:'/orderList'});
                            }else{
                                Toast(a.msg);
                            }
                        })
                }else if(this.payType===3){//支付宝
                       pay2(this.form.id,"alipay").then(a=>{
                           
                           const div = document.createElement('div') // 创建div
                           div.innerHTML = a.data // 将返回的form 放入div
                           document.body.appendChild(div) // 将上面创建的元素加入到BODY的尾部
                           document.forms[0].acceptCharset='utf-8';
                           var queryParam = '';
                           Array.prototype.slice
                               .call(
                               document
                               .querySelectorAll("input[type=hidden]"))
                               .forEach(
                               function(ele) {
                                   queryParam += ele.name
                                       + "="
                                       + encodeURIComponent(ele.value)
                                       + '&';
                               });
                               let url = document.forms[0].action+ '&' + queryParam
                               console.log(url);
                               ap.pay(url)
                       })
                }else if(this.payType===0){
                            pay2(this.form.id,"wechat").then(a=>{
                                WeixinJSBridge.invoke('getBrandWCPayRequest',{
                                    "appId":a.data.appId,
                                    "timeStamp":a.data.timeStamp,
                                    "nonceStr":a.data.nonceStr,
                                    "package":a.data.package,
                                    "signType":a.data.signType,
                                    "paySign":a.data.paySign
                                },function(res){
                                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                                        Toast.success("支付成功");
                                        this.$router.push({path:'/orderList'});
                                    }
                                })
                            })
                          
                }else{
                    pay(this.form.id,2).then(a=>{
                        if(a.code===0&&a.code==='请等待店员确认'){
                             Toast("提交成功,请于工作人员确定！！");
                             this.$router.push({path:'/orderList'});
                        }else{
                            Toast(a.msg);
                        }
                    })
                    
                }
            })
            .catch(() => {
                // on cancel
            });


       
        }
    }
}
</script>