<template>
    <div style="padding-left:0.9rem"> 
        <span style="color:#646566;font-size: 14px;margin-right:2rem">所发商品图片</span>
        <van-uploader v-model="fileList" multiple :max-count="2" :after-read="afterRead"  />    
    </div>
</template>
<script>
import { Toast } from 'vant';
import {uploadFile} from '../tool/api'
export default {
    data(){
        return{
               fileList:[],
        }
    },
    methods:{
          afterRead(file) { 
            let formData = new FormData()
            file.status = 'uploading';
            file.message = '上传中...';
            formData.append('file', file.file)
            uploadFile(formData).then(a=>{ 
                if(a.code===1){
                    file.status = 'success';
                    file.message = '上传成功';  
                    this.$emit("fallBack",a.data.url)
                }else{
                    file.status = 'fail';
                    file.message = '上传失败';
                }
            })
            
          },
    }
}
</script>