<template>
    <div>
        <van-nav-bar left-arrow left-text="返回" title="物流轨迹" @click-left="goback"/>
        <img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1584012230,1812904567&fm=26&gp=0.jpg" alt="" style="width:100%">
        <van-steps direction="vertical" :active="activeValue" >
        <van-step v-for="(item,index) in dataList" :key="index">
            <h4>{{item.log}}</h4>
            <p>{{item.addtime_text}}</p>
        </van-step>
        </van-steps>
    </div>
</template>
<script>
import {getSfRoutes} from '../tool/api'
export default {
    data(){
        return{
            id:0,
            dataList:[],
            activeValue:0,
        }
    },
    created(){
        this.id=this.$route.query.id;        
        getSfRoutes(this.id).then(a=>{
            if(a.data==null||a.data==undefined){
                this.dataList.push({log:"暂无物流信息"})
            }else{
                this.dataList=a.data.log
            }
            this.activeValue=a.data.log.length;
        });
    },
    methods: {
        goback() {
            history.back()
        }
    }
}
</script>