<template>
    <div class="container">
      <div class="user-wrap">
          <img class="user-avatar" :src="headImage" alt="">
          <div class="column">
              <span class="user-name">Hi, {{userName}}</span>
              <span class="user-desc">您的物流ID：{{userId}}</span>
          </div>
          <van-icon name="setting-o" @click="userDetail" />
      </div>
       
        <div class="form-item">
          <img class="icon" src="../assets/img/user5.png" alt="">
          <span class="vip">SPTM会员ID</span>
          <van-field class="form-input" v-model="form.id" input-align="right" type="digit" maxlength="6"  :disabled="isEnable"  placeholder="请输入六位数字的SPTM会员id"  @blur="editStoreIdMethod"/>
        </div>

        <div class="form-item">
          <img class="icon" src="../assets/img/user4.png" alt="">
          <span class="title">手机号</span>
          <van-field class="form-input" v-model="form.tel" input-align="right" type="digit" placeholder="请输入日本手机号码" @blur="editTelMethod" />
        </div>
        
        <ul class="nav-wrap">
          <li class="nav-item" @click="address">
            <img class="icon" src="../assets/img/user2.png" alt="">
            <span class="title">地址簿</span>
            <van-icon name="arrow" color="#999" size="14"/>
          </li>
          <li class="nav-item" @click="wallet">
            <img class="icon" src="../assets/img/user1.png" alt="">
            <span class="title">我的钱包</span>
            <van-icon name="arrow" color="#999" size="14"/>
          </li>
          <li class="nav-item" @click="subscribeList">
            <img class="icon" src="../assets/img/user6.png" alt="">
            <span class="title">我的预约</span>
            <van-icon name="arrow" color="#999" size="14"/>
          </li>
          <li class="nav-item" @click="getStatement">
            <img class="icon" src="../assets/img/user3.png" alt="">
            <span class="title">免责说明</span>
            <van-icon name="arrow" color="#999" size="14"/>
          </li>
        </ul>
    </div>
</template>
<script>
import { Toast,Dialog } from 'vant';
import {getUserInfo,editTel,editStoreId}from '../tool/api'
export default {
    data(){
        return{
            isEnable:false,
            sptm:{
                show:false,
                title:'',
            },
            tel:{
                showTel:false,
                title:''
            },
            
            //头像
            headImage:'',  
            //系统主键
            userId:0,
            
            //用户名
            userName:'',
            form:{
                tel:'',
                 //网店主键
                id:'',
            }
        }
    }, 
    created(){
        getUserInfo().then(a=>{
            this.headImage=a.data.avatar;
            this.userId=a.data.id;
            this.userName=a.data.username;
            this.form.tel=a.data.mobile;
            this.form.id=a.data.sptmid;
            console.log(`id:${this.form.id}`);
            if(a.data.sptmid!=undefined&&a.data.sptmid!=null&&a.data.sptmid!=''){ 
                console.log(`idtrue:${this.form.id}`);
                this.isEnable=true;
            }else{ 
                console.log(`idtfalse:${this.form.id}`);
                this.isEnable=false; 
            }
        })
    },
    methods:{   
        //跳转申明说明页面
        getStatement(){
            // this.$router.push({path:'/statement'});
             this.$router.push({path:'/statementList'});
        },
        editTelMethod(){ 
            let sessionTel=sessionStorage.getItem("tel");
            
            if(this.form.tel==undefined&&this.form.tel==null&&this.form.tel==''){
                return;
            }
            if(this.form.tel.length!=11){
                Toast.fail("电话号码长度为11,请检查是否符合");
                return;
            }
            if(this.form.tel!=sessionTel){
                editTel(this.form.tel).then(a=>{ 
                    if(a.code===1){
                        Toast("修改手机号成功");
                        sessionStorage.setItem("tel",this.form.tel); 
                    }else{
                        if(a.msg=='Mobile already exists'){
                            Toast("手机号已存在");
                        }else{
                            Toast("修改手机号失败");
                        }
                        
                    } 
                })
            }
            
        }, 
        editStoreIdMethod(){ 
            let sessionId=sessionStorage.getItem("sptmid");
            if(this.form.id!=sessionId){
                  Dialog.confirm({
                      title: '提示',
                      message: '请确认SPTM会员id为'+this.form.id+'是否正确',
                  })
                  .then(() => {
                      editStoreId(this.form.id).then(a=>{
                          if(a.code===1){
                              sessionStorage.setItem("sptmid",this.form.id); 
                              this.isEnable=true;
                              Dialog.alert({
                                  title: '提示',
                                  message: `您的物流id`+this.userId+`在使用SPTM中国快递服务需要输入该物流ID`,
                              }).then(() => {
                              
                              });
                          }else{
                              Toast("修改失败");
                          }
                      

                      })
                  })
                  .catch(() => {
                      // on cancel
                  });
            }
          
        },
        address(){
            this.$router.push({path:'/addressList'});
        },
        userDetail(){
            this.$router.push({path:'/userDetail'});
        },
        wallet(){
            this.$router.push({path:'/wallet'});
        },
        chat(){
            this.$router.push({path:'/chatIndex'});
        },
        subscribeList(){
            this.$router.push({path:'/subscribeList'});
        }
    }
}
</script>

<style scoped>
.user-wrap {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
}

.user-avatar {
  width: 50px;
  height: 50rpx;
  border-radius: 5px;
  margin-right: 10px;
}

.user-name {
  color: #333;
  font-size: 16px;
}

.user-desc {
  color: #999;
  font-size: 12px;
  margin-top: 5px;
}

.form-item {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  margin-top: 10px;
  border-bottom: 1px solid hsl(0, 0%, 96%);		
  background-color: #fff;		
}

.form-input {
  flex: 1;
  color: #333;
  font-size: 13px;
}

.nav-wrap {
  margin-top: 10px;
  padding: 0 20px;
  overflow: hidden;
  background-color: #fff;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #F5F5F5;		
  background-color: #fff;		
}
.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.title {
  flex: 1;
  color: #333;
  font-size: 13px;
}

.vip {
  color: #333;
  font-size: 13px;
}

.column {
  flex: 1;
  display:flex;
  flex-direction: column;
}

</style>